import React from 'react'
import PropTypes from 'prop-types'

const PersianDate = ({ children }) => <span>{new Date(children).toLocaleDateString('fa-IR')}</span>

export default PersianDate

PersianDate.propTypes = {
  children: PropTypes.string.isRequired,
}
